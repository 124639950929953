<template>
  <div class="TransshipmentCharges">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="转运类型">
            <el-input v-model="form.transportTypeName" placeholder="请输入转运类型" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="transportTypeName" label="转运类型" align="center"></el-table-column>
          <el-table-column prop="transportBaseFee" label="转运基础费⽤(元)" align="center"></el-table-column>
          <el-table-column prop="tstretcherBaseFee" label="担抬标准费⽤(人/楼)(元)" align="center"></el-table-column>
          <el-table-column prop="mileageBaseFee" label="每公⾥⾥程费⽤(元)" align="center"></el-table-column>
          <el-table-column prop="mileageStartFee" label="收费基础⾥程(公⾥)" align="center"></el-table-column>
          <el-table-column prop="driverBaseFee" label="驾驶员标准费⽤(元)" align="center"></el-table-column>
          <el-table-column prop="doctorBaseFee" label="医⽣标准费⽤(元)" align="center"></el-table-column>
          <el-table-column prop="nurseBaseFee" label="护⼠标准费⽤(元)" align="center"></el-table-column>
          <el-table-column prop="usherBaseFee" label="护送员标准费⽤(元)" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog
        :visible.sync="orderDialogFormVisible"
        class="order-dialog"
        @close="editDialogClose()"
      >
        <span slot="title" class="dialog-Title">转运收费标准详情</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="selectFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="转运类型" prop="transportTypeId">
              <el-select
                v-model="selectForm.transportTypeId"
                placeholder="请选择转运类型"
                filterable
                :disabled="isDisabled"
                clearable
              >
                <el-option
                  :label="item.transportTypeName"
                  :value="item.uuid"
                  v-for="(item, index) in transportTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属机构" prop="companyId">
              <el-select
                v-model="selectForm.companyId"
                placeholder="请选择所属机构"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.companyName"
                  :value="item.uuid"
                  v-for="(item, index) in companyList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转运基础费⽤(元)" prop="transportBaseFee">
              <el-input
              v-input-limit
                v-model="selectForm.transportBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="护送员标准费⽤(元)" prop="usherBaseFee">
              <el-input v-input-limit
                v-model="selectForm.usherBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="每公⾥⾥程费⽤(元)" prop="mileageBaseFee">
              <el-input v-input-limit
                v-model="selectForm.mileageBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="收费基础⾥程(公⾥)" prop="mileageStartFee">
              <el-input v-input-limit
                v-model="selectForm.mileageStartFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="驾驶员标准费⽤(元)" prop="driverBaseFee">
              <el-input v-input-limit
                v-model="selectForm.driverBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="医⽣标准费⽤(元)" prop="doctorBaseFee">
              <el-input v-input-limit
                v-model="selectForm.doctorBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="护⼠标准费⽤(元)" prop="nurseBaseFee">
              <el-input v-input-limit
                v-model="selectForm.nurseBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第四行 -->
          <el-row>
            <el-form-item label="担抬标准费⽤(每⼈每层楼层费⽤)(元)" prop="tstretcherBaseFee">
              <el-input v-input-limit
                v-model="selectForm.tstretcherBaseFee"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="selectForm.status"
                placeholder="请选择状态"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第五行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="selectForm.remark"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加转运收费标准</span>
        <el-form :model="addForm" inline ref="addForm" :rules="addFormRules" class="addForm">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="转运类型" prop="transportTypeId">
              <el-select
                v-model="addForm.transportTypeId"
                placeholder="请选择转运类型"
                filterable
                clearable
              >
                <el-option
                  :label="item.transportTypeName"
                  :value="item.uuid"
                  v-for="(item, index) in transportTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属机构" prop="companyId">
              <el-select v-model="addForm.companyId" placeholder="请选择所属机构" filterable clearable>
                <el-option
                  :label="item.companyName"
                  :value="item.uuid"
                  v-for="(item, index) in companyList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转运基础费⽤(元)" prop="transportBaseFee">
              <el-input  v-input-limit v-model="addForm.transportBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="护送员标准费⽤(元)" prop="usherBaseFee">
              <el-input v-input-limit v-model="addForm.usherBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="每公⾥⾥程费⽤(元)" prop="mileageBaseFee">
              <el-input v-input-limit v-model="addForm.mileageBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="收费基础⾥程(公⾥)" prop="mileageStartFee">
              <el-input v-input-limit v-model="addForm.mileageStartFee" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="驾驶员标准费⽤(元)" prop="driverBaseFee">
              <el-input v-input-limit v-model="addForm.driverBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="医⽣标准费⽤(元)" prop="doctorBaseFee">
              <el-input v-input-limit v-model="addForm.doctorBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="护⼠标准费⽤(元)" prop="nurseBaseFee">
              <el-input v-input-limit v-model="addForm.nurseBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-row>
          <!-- 第四行 -->
          <el-row>
            <el-form-item label="担抬标准费⽤(每⼈每层楼层费⽤)(元)">
              <el-input v-input-limit v-model="addForm.tstretcherBaseFee" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable clearable>
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第五行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'TransshipmentCharges',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { transportTypeName: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        transportTypeId: [
          { required: true, message: '请选择转运类型', trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属机构', trigger: 'blur' }
        ],
        transportBaseFee: [
          { required: true, message: '请输入转运基础费用', trigger: 'blur' }
        ],
        tstretcherBaseFee: [
          { required: true, message: '请输入担抬标准费用', trigger: 'blur' }
        ],
        mileageBaseFee: [
          { required: true, message: '请输入每公里里程费用', trigger: 'blur' }
        ],
        driverBaseFee: [
          { required: true, message: '请输入驾驶员标准费用', trigger: 'blur' }
        ],
        doctorBaseFee: [
          { required: true, message: '请输入医生标准费用', trigger: 'blur' }
        ],
        nurseBaseFee: [
          { required: true, message: '请输入护士标准费用', trigger: 'blur' }
        ],
        usherBaseFee: [
          { required: true, message: '请输入护送员标准费用', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        mileageStartFee: [
          { required: true, message: '请输入收费基础里程', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        transportTypeId: [
          { required: true, message: '请选择转运类型', trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属机构', trigger: 'blur' }
        ],
        transportBaseFee: [
          { required: true, message: '请输入转运基础费用', trigger: 'blur' }
        ],
        tstretcherBaseFee: [
          { required: true, message: '请输入担抬标准费用', trigger: 'blur' }
        ],
        mileageBaseFee: [
          { required: true, message: '请输入每公里里程费用', trigger: 'blur' }
        ],
        driverBaseFee: [
          { required: true, message: '请输入驾驶员标准费用', trigger: 'blur' }
        ],
        doctorBaseFee: [
          { required: true, message: '请输入医生标准费用', trigger: 'blur' }
        ],
        nurseBaseFee: [
          { required: true, message: '请输入护士标准费用', trigger: 'blur' }
        ],
        usherBaseFee: [
          { required: true, message: '请输入护送员标准费用', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        mileageStartFee: [
          { required: true, message: '请输入收费基础里程', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '新增', status: '0' }, { label: '正常', status: '1' }, { label: '停用', status: '2' }],
      transportTypeList: [],
      companyList: []
    }
  },
  created () {
    this.getAllCar()
    this.getAllTansType()
    this.getorderCompany()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有转运预付费
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoTransportFee/getTInfoTransportFeeList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        status: 1
      })
      console.log(res)

      if (res.statusCode !== '200') return this.$message.error('获取转运车列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 获取所有转运类型
    async getAllTansType () {
      const { data: res } = await this.$http.post('/pmService/tInfoTransportType/getTInfoTransportTypeList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        status: 1
      })
      // console.log(res);
      if (res.statusCode !== '200') return this.$message.error('获取转运类型列表失败')
      this.transportTypeList = res.data.records
    },
    //  获取所有机构
    async getorderCompany () {
      const { data: res } = await this.$http.post(
        '/userManageServer/tSysCompany/selectCompany',
        {
          pageSize: 5,
          pageNo: 1
        }
      )
      // console.log(res.data)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.companyList = res.data

      // this.tabData = res.data
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.transportTypeName === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.PageNo = 1
        const { data: res } = await this.$http.post('/pmService/tInfoTransportFee/getTInfoTransportFeeList', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          transportTypeName: this.form.transportTypeName,
          status: 1
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = +res.data.total
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm('是否确认删除', '删除转运收费标准', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/pmService/tInfoTransportFee/deleteTInfoTransportFee', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    async sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // 进行编辑操作
          const { data: res } = await this.$http.post('/pmService/tInfoTransportFee/updateTInfoTransportFee', JSON.parse(JSON.stringify(this.selectForm)))
          console.log(res)
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        this.addForm.transportTypeId = +this.addForm.transportTypeId
        this.addForm.companyId = +this.addForm.companyId
        // this.addForm.doctorBaseFee = Number(this.addForm.doctorBaseFee)
        // this.addForm.driverBaseFee = Number(this.addForm.driverBaseFee)
        // this.addForm.mileageBaseFee = Number(this.addForm.mileageBaseFee)
        // this.addForm.mileageStartFee = Number(this.addForm.mileageStartFee)
        // this.addForm.nurseBaseFee = Number(this.addForm.nurseBaseFee)
        // this.addForm.transportBaseFee = Number(this.addForm.transportBaseFee)
        // this.addForm.tstretcherBaseFee = Number(this.addForm.tstretcherBaseFee)
        // this.addForm.usherBaseFee = Number(this.addForm.usherBaseFee)
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/pmService/tInfoTransportFee/addTInfoTransportFee', JSON.parse(JSON.stringify(this.addForm)))
        console.log(res)
        console.log(JSON.parse(JSON.stringify(this.addForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    },
    editDialogClose () {
      this.$refs.selectForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.TransshipmentCharges {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
